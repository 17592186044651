import debug from 'debug';
import { enGB } from 'date-fns/locale';
import { zip } from './array';
import { Bus } from './bus';

const Log = debug('{locale}');

export function i18n(template, ...args) {
    const label = template.join('\x01');
    const locale = i18n.db[i18n.locale]
        ? i18n.db[i18n.locale]
        : { [label]: { t: template.slice(), v: template.map((_, ind) => ind) } };
    const info = locale[label]
        || { t: template };

    return info.t.slice(1).reduce((text, translation) => text + args.shift() + translation, info.t[0]);
}

export function i18v(template, ...args) {
    const locale = i18n.db[i18n.locale]
        ? i18n.db[i18n.locale]
        : {};
    const values = template.slice(1)
        .map((_, i) => args[i])
        .map(text => locale[text] || text)
        .map(info => (info.t ? info.t[0] : info));

    return zip(template, values).join('');
}

export function i18a(template, ...args) {
    const label = template.join('\x01');
    const locale = i18n.db[i18n.locale]
        ? i18n.db[i18n.locale]
        : {};
    const info = locale[label]
        || { t: template };

    const values = template.slice(1)
        .map((_, i) => args[i])
        .map(text => locale[text] || text)
        .map(info => (info.t ? info.t[0] : info));

    return zip(info.t, values).join('');
}

const locales = { en: enGB };
i18n.addLocale = locale => {
    Object.assign(locales, locale);
};

i18n.register = lang => {
    i18n.locale = lang || 'en';
    i18n.lang = locales[lang] || enGB;
    localStorage.setItem('lang', lang);
};

i18n.register(localStorage.getItem('lang') || 'sv');

i18n.db = {};
i18n.set = locale => (tCurrent, ...rCurrent) => {
    const key = tCurrent.join('\x01');
    let db = i18n.db[locale] || (i18n.db[locale] = {});
    db[key] = {
        t: tCurrent.slice(),
        v: rCurrent.map((value, i) => i)
    };
    const config = {
        for: other => (tOther, ...rOther) => {
            db = i18n.db[other] || (i18n.db[other] = {});
            db[key] = {
                t: tOther.slice(),
                v: rOther.map((value, i) => rCurrent.indexOf(value))
            };
            return config;
        }
    };
    return config;
};

Bus.observe('ChangeLanguage', SetUpLanguage);
Bus.listen('ChangeLanguage', SetUpLanguage);
Bus.listen('locale', i18n.register);

export function SetUpLanguage(locale) {
    Log('Language %o', locale);
    if (!localStorage.locale) {
        localStorage.locale = locale;
    }
    i18n.register(locale);
}

export const _ = i18n;
export const t = i18a;
