import { EventEmitter } from 'events';
import { mainStory } from 'storyboard';
import { InitHub } from './hub';
import { Auth } from './authenticate';

const emitter = new EventEmitter();

const resolver = (resolve, reject) => {
    resolve.emitEvent = (name, data, dom = document) => {
        const event = new CustomEvent(name, { detail: data });
        dom.dispatchEvent(event);
    };
    emitter.addListener('hub:init', config => InitHub({ ...config, bus: resolve }).catch(reject));
};
export const Bus = new Promise(resolver);
Bus.catch(err => mainStory.warn('bus', `${err}`, { attach: err, attachLevel: 'trace' }));

Bus.Init = config => emitter.emit('hub:init', config);

Bus.observe = (topic, callback) => {
    const logger = (...args) => {
        mainStory.info('bus', 'observe', { attach: args, attachLevel: 'trace' });
        callback(...args);
    };
    Bus.then(hub => hub.on(topic, logger));
    return () => Bus.then(hub => hub.off(topic, logger));
};

Bus.listen = (topic, callback) => {
    const logger = (...args) => {
        mainStory.info('bus', 'listen', { attach: args, attachLevel: 'trace' });
        callback(...args);
    };
    emitter.on(topic, logger);
    return () => emitter.removeListener(topic, logger);
};

Bus.emit = (...args) => {
    Bus.emitLocal(...args);
    Bus.emitServer(...args);
    mainStory.info('bus', 'send', { attach: args, attachLevel: 'trace' });
};

Bus.emitSecure = (...args) => {
    Bus.emitLocal(...args);
    Bus.secure(...args);
    mainStory.info('bus', 'send secure', { attach: args, attachLevel: 'trace' });
};

Bus.emitLocal = (...args) => {
    emitter.emit(...args);
};

Bus.emitServer = (...args) => {
    Bus.then(hub => hub.send(...args)).catch(err => mainStory.warn('bus', `${err}`, { attach: err, attachLevel: 'trace' }));
};

Bus.secure = (topic, ...args) => {
    Bus.emit(topic, Auth, ...args);
};

Bus.emitEvent = (name, data, dom = document) => {
    const event = new CustomEvent(name, { detail: data });
    dom.dispatchEvent(event);
};

Bus.listenEvent = (name, callback, dom = document) => {
    const logger = (...args) => {
        mainStory.info('bus', `listen (${name}) event`, { attach: args, attachLevel: 'trace' });
        callback(...args);
    };
    dom.addEventListener(name, logger);
    return () => dom.removeEventListener(name, logger);
};

Bus.emit.local = Bus.emitLocal;
Bus.emit.server = Bus.emitServer;
Bus.emit.event = Bus.emitEvent;
Bus.listen.event = Bus.listenEvent;
Bus.emit.window = (name, data) => window.dispatchEvent(new CustomEvent(name, { detail: data }));
Bus.listen.window = (name, callback) => {
    const logger = (...args) => {
        mainStory.info('bus', 'listen window', { attach: args, attachLevel: 'trace' });
        callback(...args);
    };
    window.addEventListener(name, logger);
    return () => document.removeEventListener(name, logger);
};

export function LocalBus() {
    const bus = new EventEmitter();
    return {
        listen(topic, callback) {
            bus.addListener(topic, callback);
            return () => bus.removeListener(topic, callback);
        },
        emit(topic, ...args) {
            bus.emit(topic, ...args);
            return this; // allows to chain emits
        }
    };
}
