import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Loading } from 'common/components/loading';

export const Login = {
    oninit() {
        this.username = '';
        this.credentials = '';
        this.disabled = true;

        this.password = ({ target }) => {
            this.credentials = Buffer.from(target.value).toString('base64');
            this.disabled = !(this.credentials && this.user);
        };
        this.user = ({ target }) => {
            this.username = Buffer.from(target.value).toString('base64');
            this.disabled = !(this.credentials && this.user);
        };
        this.submit = (e) => {
            e.preventDefault();
            Bus.emit('Login', this.username, this.credentials);
        };
    },
    view({ attrs }) {
        const { error, loading } = attrs;
        if (loading) {
            return (<Loading class="loading-lg pt-2 mt-2" style="" />);
        }
        return (
            <div class="columns mx-1 mt-2">
                <form class="column col-3 col-mx-auto mt-2">
                    {error ? (<label class="text-error">The name or password you entered is not correct</label>) : ''}
                    <div class={`form-group ${error ? 'has-error' : ''}`}>
                        <label class="form-label" for="input-name">Name:</label>
                        <input class="form-input" type="text" id="input-name" placeholder="Name" autocomplete="username"
                            onkeyup={this.user} />
                    </div>
                    <div class={`form-group ${error ? 'has-error' : ''}`}>
                        <label class="form-label" for="input-password">Password:</label>
                        <input class="form-input" type="password" id="input-password" placeholder="Password" autocomplete="current-password"
                            onkeyup={this.password} />
                    </div>
                    <div class="form-group float-right">
                        <button class="btn btn-lg btn-primary" onclick={e => this.submit(e)}>Login</button>
                    </div>
                </form>
            </div>
        );
    }
};
