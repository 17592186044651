import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';

import './main.scss';

export function AccountList() {
    const accounts = new Observable([])
        .filter((value, oldValue) => JSON.stringify(value) !== JSON.stringify(oldValue));
    const submit = (e, target) => {
        e.preventDefault();
        Bus.secure(target);
    };
    const noAccounts = e => Bus.secure('NoAccounts');
    const newAccount = e => submit(e, 'NewAccount');
    return {
        view({ attrs }) {
            accounts(attrs.accounts);
            return (
                <div class="columns mx-1">
                    <div class="column col-3">
                        <button class="btn" onclick={noAccounts}><icon class="icon icon-back" /></button>
                    </div>
                    <div class="column col-6 col-mr-auto">
                        {accounts.value.map(account => <Account key={account.id} data={account} />)}
                        <div class="d-inline-flex my-2">
                            <button class="btn btn-primary" onclick={newAccount}><icon class="icon icon-plus" /></button>
                            <h5 class="ml-2 pt-1">
                                Add New User
                            </h5>
                        </div>
                    </div>
                </div>
            );
        }
    };
}
function Account({ attrs }) {
    const { data } = attrs;
    const account = new Observable(data)
        .filter((value, oldValue) => JSON.stringify(value) !== JSON.stringify(oldValue))
        .each(value => username(value.name)) // eslint-disable-line no-use-before-define
        .each(value => password('')); // eslint-disable-line no-use-before-define
    const disabled = new Observable(data.name === 'admin');
    const username = new Observable(data.name)
        .each(value => disabled(value === 'admin'));
    const password = new Observable('');
    const submit = (e, target) => {
        e.preventDefault();
        const data = { ...account.value, name: username.value, password: password.value };
        Bus.secure(target, data);
    };
    return {
        view({ attrs }) {
            account(attrs.data);
            return (
                <form class="form-horizontal my-2" style="height:150px">
                    <div class="form-group">
                        <div class="col-3">
                            <label class="form-label" for={`input-username-${account.value.id}`}>Username</label>
                        </div>
                        <div class="col-9">
                            <input
                                class="form-input"
                                type="text"
                                id={`input-username-${account.value.id}`}
                                placeholder="Username"
                                value={username.value}
                                disabled={disabled.value}
                                oninput={ev => username(ev.target.value)}
                            />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-3">
                            <label class="form-label" for={`input-password-${account.value.id}`}>Password</label>
                        </div>
                        <div class="col-9">
                            <input
                                class="form-input"
                                type="text"
                                id={`input-password-${account.value.id}`}
                                placeholder="New Password"
                                value={password.value}
                                disabled={disabled.value}
                                oninput={ev => password(ev.target.value)}
                            />
                        </div>
                    </div>
                    <div class="form-group my-2 float-right">
                        <button
                            class="btn btn-success mx-2 tooltip"
                            data-tooltip={`Save ${account.value.name}`}
                            disabled={disabled.value}
                            onclick={e => submit(e, 'SaveAccount')}
                        >
                            <icon class="icon icon-check" />
                        </button>
                        <button
                            class="btn btn-error mx-2 tooltip"
                            data-tooltip={`Delete ${account.value.name}`}
                            disabled={disabled.value}
                            onclick={e => submit(e, 'DeleteAccount')}
                        >
                            <icon class="icon icon-delete" />
                        </button>
                    </div>
                </form>
            );
        }
    };
}
