import m from 'mithril';
import { Loading } from 'common/components/loading';
import { _ } from 'common/utils/localization';
import { jsPDF } from 'jspdf';
import { Chart } from 'chart.js';

import one from 'common/img/report/01-ingen-fattigdom.jpg';
import two from 'common/img/report/02-ingen-hunger.jpg';
import three from 'common/img/report/03-god-halsa-och-valbefinnande.jpg';
import four from 'common/img/report/04_god_utbildning.jpg';
import five from 'common/img/report/05-jamstalldhet.jpg';
import six from 'common/img/report/06-rent-vatten-och-sanitet-for-alla.jpg';
import seven from 'common/img/report/07-hallbar-energi-for-alla.jpg';
import eight from 'common/img/report/08-anstandiga-arbetsvillkor-och-ekonomisk-tillvaxt.jpg';
import nine from 'common/img/report/09-hallbar-industri-innovationer-och-infrastruktur.jpg';
import ten from 'common/img/report/10-minskad-ojamnlikhet.jpg';
import eleven from 'common/img/report/11-hallbara-stader-och-samhallen.jpg';
import twelve from 'common/img/report/12-hallbar-konsumtion-och-produktion.jpg';
import thirteen from 'common/img/report/13-bekampa-klimatforandringarna.jpg';
import fourteen from 'common/img/report/14_hav_och_marina_resurser.jpg';
import fifteen from 'common/img/report/15_ekosystem.jpg';
import sixteen from 'common/img/report/16_fredliga_samhallen.jpg';
import seventeen from 'common/img/report/17_genomforande_och_partnerskap.jpg';

import dots from 'common/img/report/dots.jpg';
import lastpage from 'common/img/report/lastpage.jpg';

import extrapageSV from 'common/img/report/extrapage.jpg';

import firstpageEN from 'common/img/report/en/firstpage.jpg';
import firstpageSV from 'common/img/report/sv/firstpage.jpg';

import extraNineteenEN from 'common/img/report/en/19-extra.jpg';
import extraNineteenSV from 'common/img/report/sv/19-extra.jpg';

const firstpage = {
    en: firstpageEN,
    sv: firstpageSV
};

const extrapage = {
    en: extraNineteenEN,
    sv: extrapageSV
};

const extraNineteen = {
    en: extraNineteenEN,
    sv: extraNineteenSV
};

export const PrintPDF = {
    oninit() {
        this.errormsg = null;
        this.return = null;
        this.data = null;
    },
    oncreate({ dom }) {
        if (this.data !== null) {
            setTimeout(() => {
                const msg = Print(this.data, dom);
                if (msg === null) {
                    this.return();
                } else {
                    this.errormsg = msg.toString();
                    m.redraw();
                }
            }, 5);
        }
    },
    view({ attrs }) {
        const { data, printed } = attrs;
        this.data = data;
        this.return = printed;
        return (
            <div class="rendering-pdf-screen">
                {this.errormsg !== null
                    ? (
                        <div>
                            <h1>There was an error printing your pdf, please contact an administrator</h1>
                            <h2>{this.errormsg}</h2>
                            <button class="btn btn-lg" onclick={e => this.return()}>back</button>
                        </div>
                    )
                    : this.data !== null
                        ? (
                            <div>
                                <h4>Generating PDF, please wait</h4>
                                <Loading class="loading-lg" />
                            </div>
                        )
                        : (
                            <div>
                                <h1>There was an error in receiving the data for print, please contact an administrator</h1>
                                <button class="btn btn-lg" onclick={e => this.return()}>back</button>
                            </div>
                        )
                }
                <div id="chart-container">
                    <canvas id="chart" width="180" height="180"></canvas>
                </div>
            </div>
        );
    }
};

function Print(courseStats, dom) {
    try {
        const imgs = [one, two, three, four, five, six, seven, eight, nine, ten, eleven, twelve, thirteen, fourteen, fifteen, sixteen, seventeen];

        // eslint-disable-next-line new-cap
        const doc = new jsPDF('portrait', 'mm', 'a4', true);

        const textOptions = { baseline: 'top', maxWidth: 130 };

        const width = doc.internal.pageSize.getWidth(); // 210
        const height = doc.internal.pageSize.getHeight(); // 297

        doc.addImage(firstpage[_.locale], 'JPG', 0, 0, width, height, '', 'FAST');

        const x = 15;
        const y = 10;

        courseStats.pageStatistics.forEach((pageStat, pageIndex) => {
            let pageHeight = 0;
            if (pageStat.page.showQuestion) {
                pageStat.questionStatistics.forEach((questionStat, questionsIndex) => {
                    if (questionsIndex === 0 || questionsIndex % 4 === 0) {
                        doc.addPage();
                        pageHeight = 60;

                        // create header
                        doc.addImage(imgs[pageIndex], 'JPG', x, y, 40, 40, `${pageIndex}`, '', 'FAST');
                        doc.setFontSize(38);
                        doc.setTextColor(186, 186, 186);
                        doc.text(`${_`Report`} ${pageStat.page.name}`, x + 45, y + 10, { baseline: 'top', maxWidth: 130 });
                        doc.setTextColor(0, 0, 0);

                        // chart legends
                        doc.setFontSize(12);
                        doc.setFillColor(26, 147, 111);
                        doc.circle(157 - x, 55, 3, 'F');
                        doc.text(_`Yes`, 162 - x, 55, { baseline: 'middle' });

                        doc.setFillColor(239, 38, 56);
                        doc.circle(174 - x, 55, 3, 'F');
                        doc.text(_`No`, 179 - x, 55, { baseline: 'middle' });

                        doc.setFillColor(234, 196, 53);
                        doc.circle(191 - x, 55, 3, 'F');
                        doc.text(_`Don't know`, 196 - x, 55, { baseline: 'middle' });

                        doc.setFontSize(13);
                        doc.setFont('helvetica', 'bold');
                        doc.text(pageStat.page.multipleChoiceQuestion, x, 55, { baseline: 'middle', maxWidth: 120 });
                        const splitMultipleChoiceQuestion = doc.splitTextToSize(pageStat.page.multipleChoiceQuestion, 120);
                        for (let index = 0; index < splitMultipleChoiceQuestion.length; index++) {
                            pageHeight += 3;
                        }
                    }
                    doc.setFontSize(11);
                    doc.setFont('helvetica', 'bold');
                    const total = questionStat.yes + questionStat.no + questionStat.idk || 1;
                    // chart && question
                    if (questionsIndex === 0 || questionsIndex % 4 === 0) {
                        if ([questionStat.yes, questionStat.no, questionStat.idk].reduce((acc, value) => acc + value) > 0) {
                            doc.addImage(createChart([questionStat.yes, questionStat.no, questionStat.idk], dom),
                                'PNG', 155, pageHeight + 3.5, 43, 43, '', 'FAST');
                        }
                        doc.text(questionStat.question, x, pageHeight += 3.5, textOptions);
                    } else {
                        if ([questionStat.yes, questionStat.no, questionStat.idk].reduce((acc, value) => acc + value) > 0) {
                            doc.addImage(createChart([questionStat.yes, questionStat.no, questionStat.idk], dom),
                                'PNG', 155, pageHeight + 9.5, 43, 43, '', 'FAST');
                        }
                        doc.text(questionStat.question, x, pageHeight += 9, textOptions);
                    }
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(11);
                    // answers text
                    doc.text(`${Math.round(((questionStat.yes / total) * 100) || 0)}% ${_`think that it fits in with your organization`}`,
                        x, pageHeight += 20, textOptions);
                    doc.text(`${Math.round(((questionStat.no / total) * 100) || 0)}% ${_`believe that it does NOT fit your organization`}`,
                        x, pageHeight += 5.5, textOptions);
                    doc.text(`${Math.round(((questionStat.idk / total) * 100) || 0)}% ${_`do not know if it fits in with your organization`}`,
                        x, pageHeight += 5.5, textOptions);
                    doc.setFontSize(13);
                    doc.setTextColor(189, 0, 0);
                    if (pageStat.page.importantSelection === 1) {
                        const val = Math.round(questionStat.importantValue * 100) / 100; // Math.round(((questionStat.importantValue/5) * 100) || 0)%
                        doc.text(` ${_`Importance Grade`}: ${val}`, x, pageHeight += 7.5, textOptions);
                    } else {
                        let val = Math.round(((questionStat.shouldDoIt / total) * 100) || 0);
                        if (!Number.isFinite(val)) {
                            val = 0;
                        } else if (val > 100) {
                            val = 100;
                        }
                        doc.text(`${val}% ${_`believe that the issue is important`}`, x, pageHeight += 7.5, textOptions);
                    }
                    doc.setTextColor(0, 0, 0);
                    doc.addImage(dots, 'JPG', x, pageHeight += 8, width - (x * 2), 5, '', 'FAST');
                });
            }

            if (pageStat.page.showFreeText) {
                doc.addPage();
                pageHeight = 55;

                // create header
                doc.addImage(imgs[pageIndex], 'JPG', x, y, 40, 40, `${pageIndex}`, '', 'FAST');
                doc.setFontSize(38);
                doc.setTextColor(186, 186, 186);
                doc.text(`${_`Report`} ${pageStat.page.name}`, x + 45, y + 10, { baseline: 'top', maxWidth: 130 });
                doc.setTextColor(0, 0, 0);

                doc.setFontSize(11);
                doc.setFont('helvetica', 'bold');
                doc.text(pageStat.page.freetextQuestion, x, pageHeight, { baseline: 'top', maxWidth: 180 });
                const splitQuestion = doc.splitTextToSize(pageStat.page.freetextQuestion, 180);
                for (let index = 0; index < splitQuestion.length; index++) {
                    pageHeight += 3;
                }
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(11);
                let prevRows = 0;
                let totalRows = 0;
                pageStat.thoughts.forEach((thought, index) => {
                    // comments
                    const splitText = doc.splitTextToSize(thought, 180);
                    splitText[0] = ` - ${splitText[0]}`;
                    if (splitText.length > 4) {
                        splitText.splice(4);
                        splitText[3] = `${splitText[3]}...`;
                    }
                    totalRows += splitText.length;
                    if (totalRows < 42) {
                        if (index === 0) {
                            pageHeight += 7;
                        } else {
                            pageHeight += ((5 * prevRows) + 1);
                        }
                        doc.text(splitText, x, pageHeight, { baseline: 'top' });
                    }
                    prevRows = splitText.length;
                });
            }
        });

        // if there should be a maximum amount of pages as of 2021-10-13 this should no longer be the case
        // const numerOfPages = doc.internal.getNumberOfPages();
        // for (let i = numerOfPages; i < 18; i++) {
        //     doc.addPage();
        //     doc.addImage(extrapage[_.locale], 'JPG', 0, 0, width, height, '', 'FAST');
        // }

        doc.addPage();
        doc.addImage(extraNineteen[_.locale], 'JPG', 0, 0, width, height, '', 'FAST');

        doc.addPage();
        doc.addImage(lastpage, 'JPG', 0, 0, width, height, '', 'FAST');

        // download automatically
        // doc.save(`Rapport ${courseStats.course.name} - ${courseStats.group.name}.pdf`);

        // opens a preview first
        window.open(doc.output('bloburl'));

        return null;
    } catch (error) {
        return error;
    }
}

let chart = null;
function createChart(data, dom) {
    if (chart) {
        chart.destroy();
    }
    const canvas = dom.querySelector('#chart');
    const ctx = canvas.getContext('2d');
    chart = new Chart(ctx, {
        type: 'pie',
        data: {
            datasets: [{
                data,
                backgroundColor: [
                    'rgba(26, 147, 111, 1)',
                    'rgba(239, 38, 56, 1)',
                    'rgba(234, 196, 53, 1)',
                ],
                borderColor: [
                    'rgba(26, 147, 111, 1)',
                    'rgba(239, 38, 56, 1)',
                    'rgba(234, 196, 53, 1)',
                ],
                borderWidth: 0
            }]
        },
        options: {
            animation: 0,
            responsiveAnimationDuration: 0
        }
    });
    return canvas.toDataURL('image/png', 0.30);
}
