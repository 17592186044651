import { mainStory, addListener } from 'storyboard';
import consoleListener from 'storyboard-listener-console';
import browserExtListener from 'storyboard-listener-browser-extension';
import wsListener from './listener-ws-client';

addListener(consoleListener);
addListener(browserExtListener);
addListener(wsListener, { uploadClientStories: false, throttleUpload: 1000, clockSync: true });

function ignoreLogger(key, value) {
    if (key !== 'logger') {
        return value;
    }
    return undefined;
}

function strip(value) {
    return JSON.parse(JSON.stringify(value, ignoreLogger));
}

export { mainStory, ignoreLogger, strip };
