import querystring from 'querystring';
import { EventEmitter } from 'events';

const AUTH_PODIUM = Buffer.from('AUTH_PODIUM_STORE').toString('base64');

const schematic = {
    user: '',
    email: '',
    course: '',
    courseId: '',
    page: ''
};

const emitter = new EventEmitter();

const autologout = {
  init() {
    this.clearTick();
    this._auto = true;
    this._isTicking = false;
  },
  clearTick() {
    this._tick = null;
  },
  startTicking() {
    if (this._auto) {
      this._isTicking = true;
      this.tick();
    }
  },
  tick() {
    if (this._auto && this._isTicking) {
      this._tick = new Date();
    }
  }
};
Object.defineProperty(autologout, 'auto', { get() { return this._auto; } });
const autoLogoutProperties = {
  init: { value: () => autologout.init() },
  clear: { value: () => autologout.clearTick() },
  tick: { value: () => autologout.tick() },
  last_tick: { get: () => autologout._auto && autologout._isTicking && autologout._tick, enumerable: true },
};

const auth = { ...schematic };
const prototype = {
  signIn(data) {
    Object.assign(auth, verify(data));
    localStorage[AUTH_PODIUM] = Buffer.from(JSON.stringify(auth)).toString('base64');
    autologout.startTicking();
    emitter.emit('login', JSON.parse(JSON.stringify(auth)));
    return this;
  },
  signInFromQuery() {
    const data = querystring.parse(window.location.search.substr(1));
    this.signIn(data);
  },
  logout() {
    prototype.clear();
    emitter.emit('logout');
  },
  clearStorage() {
    localStorage.removeItem(AUTH_PODIUM);
    emitter.emit('clear');
  },
  clearData() {
    Object.assign(auth, schematic);
    emitter.emit('reset');
    return this;
  },
  clear() {
    prototype.clearStorage();
    prototype.clearData();
  },
  listen(topic, listener) {
    if (typeof listener === 'function') {
      emitter.addListener(topic, listener);
      return () => emitter.removeListener(topic, listener);
    }
    return () => {};
  },
  observe(topic, observable) {
    if (observable) {
      if (observable.isObservable) {
        emitter.addListener(topic, observable);
        return () => emitter.removeListener(topic, observable);
      }
      if (typeof observable === 'function') {
        emitter.addListener(topic, observable);
        return () => emitter.removeListener(topic, observable);
      }
    }
    return () => {};
  },
};

// Note: properties automatically created from schematic
const properties = Object.keys(schematic)
  .map(key => ({ [key]: { get: () => auth[key], enumerable: true } }))
  .concat({ isLoggedIn: { get: () => auth.user !== '', enumerable: true } })
  .concat({ inStorage: { get: () => localStorage[AUTH_PODIUM], enumerable: true } })
  .concat({ autologout: { get: () => Object.create(null, autoLogoutProperties), enumerable: true } })
  .reduce(Object.assign);
export const Auth = Object.create(prototype, properties);

function verify(data) {
    const keys = Object.keys(schematic);
    Object.keys(data)
        .filter(key => !keys.includes(key))
        .forEach(key => delete data[key]);
  return data;
}
