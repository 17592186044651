import debug from 'debug';
import { sv } from 'date-fns/locale';
import { _ } from '../utils/localization';

const Log = debug('{locale}');
Log('Localized sv');

/* eslint-disable no-unused-expressions */
_.addLocale({ sv });

// PDF GENERATOR
_.set('en')`Report`
    .for('sv')`Rapport`;

_.set('en')`Yes`
    .for('sv')`Ja`;
_.set('en')`No`
    .for('sv')`Nej`;
_.set('en')`Don't know`
    .for('sv')`Vet ej`;

_.set('en')`think that it fits in with your organization`
    .for('sv')`anser att det stämmer in på er organisation`;
_.set('en')`believe that it does NOT fit your organization`
    .for('sv')`anser att det INTE stämmer in på er organisation`;
_.set('en')`do not know if it fits in with your organization`
    .for('sv')`vet inte om det stämmer in på er organisation`;
_.set('en')`believe that the issue is important`
    .for('sv')`anser att frågan är viktig`;
_.set('en')`Importance Grade`
    .for('sv')`Viktighetsbetyg`;

// Agora Form

_.set('en')`Do you think the following is true for your organization?`
    .for('sv')`Tycker du att följande stämmer in på din organisation ?`;
_.set('en')`Important to me!`
    .for('sv')`Viktigt för mig!`;
_.set('en')`very much`
    .for('sv')`mycket`;
_.set('en')`not so much`
    .for('sv')`lite`;
