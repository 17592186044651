/*
    Wiki: http://transportlead.westeurope.cloudapp.azure.com/projects/propsy/wiki/Common#zip
*/

export function zip(a, b) {
    if (a.length < b.length) {
        // eslint-disable-next-line prefer-destructuring, no-param-reassign
        b = [a, a = b][0];
    }
    return a.reduce((c, v, i) => [...c, v, b[i]], []);
}

/*
    Wiki: http://transportlead.westeurope.cloudapp.azure.com/projects/propsy/wiki/Common#divider
*/

export function divide(arr, f) {
    return arr.reduce(([T, F], x, i, arr) => {
        if (f(x, i, arr) === false) {
            return [T, [...F, x]];
        }
        return [[...T, x], F];
    }, [[], []]);
}
