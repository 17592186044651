import m from 'mithril';

export const Card = {
    view({ attrs, children }) {
        const { logger } = attrs;
        const header = children.filter(child => child && child.attrs && child.attrs.slot === 'header').map(child => setLogger(child, logger));
        const nav = children.filter(child => child && child.attrs && child.attrs.slot === 'nav').map(child => setLogger(child, logger));
        const footer = children.filter(child => child && child.attrs && child.attrs.slot === 'footer').map(child => setLogger(child, logger));
        const content = children.filter(child => child && (!child.attrs || !['header', 'nav', 'footer'].includes(child.attrs.slot))).map(child => setLogger(child, logger));
        return (
            <div class={`card ${attrs.class || ''}`}>
                {header.length ? <div class="card-header">{header}</div> : null}
                {nav.length ? <div class="card-nav">{nav}</div> : null}
                {content.length ? <div class="card-body">{content}</div> : null}
                {footer.length ? <div class="card-footer">{footer}</div> : null}
            </div>
        );
    }
};

function setLogger(child, logger) {
    if (child.attrs) {
        child.attrs.logger = logger || child.attrs.logger;
    }
    return child;
}
