import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Auth } from 'common/utils/authenticate';
import { Observable } from 'common/utils/observable';
import exceedLogo from 'common/img/exceed.png';

import { Login } from './login';
import { Main } from './main';
import { Form } from './form';
import { AccountList } from './account';

import './main.scss';

export const Archon = {
    oninit() {
        this._listeners = [];
        this.error = false;
        this.loading = new Observable(false)
            .each(() => m.redraw())
            .each((value, Oldvalue) => this.error = (value === false && Oldvalue === true));
        this._listeners.push(Bus.listen('Login', () => this.loading(true)));
        this._listeners.push(Bus.observe('Logout', () => this.loading(false)));
        this._listeners.push(Bus.observe('LoginSuccess', (id, token, user) => {
            Auth.signIn({ id, token, user });
            this.loading = false;
            this.user = user;
            m.redraw();
        }));

        this._listeners.push(Bus.observe('Course', data => this.course(data)));
        this._listeners.push(Bus.observe('Groups', data => this.groups(data)));
        this.course = new Observable(null)
            .each(() => m.redraw());
        this.groups = new Observable(null)
            .each(() => m.redraw());

        this.accounts = new Observable(null)
            .each(() => m.redraw());
        this._listeners.push(Bus.observe('Accounts', data => this.accounts(data)));
    },
    onremove() {
        this._listeners.forEach(dispose => dispose());
    },
    view({ attrs }) {
        return (
            <div>
                <header class="navbar">
                    <section class="navbar-section mb-2">
                        <img src={exceedLogo} alt="logo" />
                        <h1 class="text-left ml-2 pl-2">Course Control Panel</h1>
                    </section>
                </header>

                {Auth.isLoggedIn
                    ? (this.course.value !== null
                        ? (<Form course={this.course.value} groups={this.groups.value} />)
                        : (this.accounts.value !== null
                            ? (<AccountList accounts={this.accounts.value} />)
                            : (<Main user={this.user} />)))
                    : (<Login loading={this.loading.value} error={this.error} />)
                }

                <footer>
                    <div class="center">
                        Copyright Exceedconsulting.se
                    </div>
                </footer>
            </div>
        );
    }
};
