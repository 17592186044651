import m from 'mithril';

export const Loading = {
    view({ attrs }) {
        return (
            <div class={`panel ${attrs.class}`}>
                <div class="loading loading-lg"></div>
            </div>
        );
    }
};
