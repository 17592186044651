import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Observable } from 'common/utils/observable';

import { NotFound } from './notfound';

import './main.scss';

export const Agora = {
    oninit({ attrs }) {
        this._listeners = [];
        this._listeners.push(Bus.observe('Reload', () => {
            const { config } = attrs;
            Bus.emit(
                'GetQuestions',
                parseInt(config.groupId, 10),
                config.groupName,
                parseInt(config.courseId, 10),
                config.email,
                config.user,
                parseInt(config.page, 10)
            );
        }));
        this._listeners.push(Bus.observe('AnswerPage', data => this.questions(data)));
        this.questions = new Observable(null)
            .each(() => m.redraw());

        this.saved = new Observable('success')
            .each(() => m.redraw());

        this._listeners.push(Bus.observe('Message', (data) => {
            if (data === 'success' || data === 'loading' || data === 'error') {
                this.saved(data);
            }
        }));
        this._listeners.push(Bus.listen('SaveQuestions', () => this.saved('loading')));

        this.save = (value, id, type) => {
            this.questions.value.answers.find(x => x.id === id)[type] = value;
            if (type === 'answer' && value === 1) {
                this.questions.value.answers.find(x => x.id === id).shouldDoIt = false;
            }
            Bus.emit('SaveQuestions', this.questions.value);
        };
        this.saveThoughts = (value) => {
            this.questions.value.ownThoughts = value;
            Bus.emit('SaveQuestions', this.questions.value);
        };
    },
    oncreate({ attrs }) {
        const { config } = attrs;
        Bus.emit(
            'GetQuestions',
            parseInt(config.groupId, 10),
            config.groupName,
            parseInt(config.courseId, 10),
            config.email,
            config.user,
            parseInt(config.page, 10)
        );
    },
    onremove() {
        this._listeners.forEach(dispose => dispose());
    },
    view({ attrs }) {
        const { config } = attrs;
        if (this.questions.value === null) {
            return (<NotFound />);
        }
        return (
            <div class="m-2">
                <div class="columns">
                    <div class={`column col-1 col-ml-auto p-2 save-size text-${this.saved}`}>
                        {this.saved.value === 'success' ? (<div class="text-center"><icon class="icon icon-check" /></div>) : ''}
                        {this.saved.value === 'loading' ? (<div class="fullsize loading" />) : ''}
                        {this.saved.value === 'error' ? (<div class="text-center"><icon class="icon icon-cross" /></div>) : ''}
                    </div>
                </div>

                {this.questions.value.showQuestion ? [
                    // eslint-disable-next-line mithril/jsx-key
                    <div class="columns mb-2">
                        <div class="column col-6 col-sm-12">
                            <h5>
                                {this.questions.value.multipleChoiceQuestion}
                            </h5>
                        </div>
                        <div class="column col-3 col-sm-8 columns text-center">
                            <div class="column col-3">
                                <h5>
                                    {_`Yes`}
                                </h5>
                            </div>
                            <div class="column col-3">
                                <h5>
                                    {_`No`}
                                </h5>
                            </div>
                            <div class="column col-6">
                                <h5>
                                    {_`Don't know`}
                                </h5>
                            </div>
                        </div>
                        <div class="column col-3 col-sm-4 text-center">
                            {this.questions.value.importantSelection === 0 ? (
                                <h5>
                                    {_`Important to me!`}
                                </h5>
                            ) : [
                                <h5 key="title">
                                    {_`Important to me!`}
                                </h5>,
                                <p key="subtitle" class="important-subtitle">
                                    (1={_`not so much`} - 5={_`very much`})
                                </p>,
                                <div key="RadioBtnHeader" class="form-group">
                                    <RadioBtnHeader value={1} />
                                    <RadioBtnHeader value={2} />
                                    <RadioBtnHeader value={3} />
                                    <RadioBtnHeader value={4} />
                                    <RadioBtnHeader value={5} />
                                </div>
                            ]}
                        </div>
                    </div>,
                    this.questions.value.answers.map((a, index) => (
                        <div key={index} class="columns">
                            <div class="column col-6 col-sm-12">
                                <h6>
                                    {index + 1}. {a.question}
                                </h6>
                            </div>
                            <div class="form-group column col-3 col-sm-8 columns text-center">
                                <div class="column col-3">
                                    <label class="form-radio m-0 p-0">
                                        <input type="radio" name={`radio-${index}`} checked={a.answer === 1}
                                            oninput={e => this.save(1, a.id, 'answer')} />
                                        <i class="form-icon p-relative" />
                                    </label>
                                </div>
                                <div class="column col-3">
                                    <label class="form-radio m-0 p-0">
                                        <input type="radio" name={`radio-${index}`} checked={a.answer === 2}
                                            oninput={e => this.save(2, a.id, 'answer')} />
                                        <i class="form-icon p-relative" />
                                    </label>
                                </div>
                                <div class="column col-6">
                                    <label class="form-radio m-0 p-0">
                                        <input type="radio" name={`radio-${index}`} checked={a.answer === 3}
                                            oninput={e => this.save(3, a.id, 'answer')} />
                                        <i class="form-icon p-relative" />
                                    </label>
                                </div>
                            </div>
                            {this.questions.value.importantSelection === 0 ? (
                                <div class="form-group column col-3 col-sm-4">
                                    <label class="form-checkbox text-center m-0 p-0">
                                        <input type="checkbox" name={`check-${index}`} checked={a.shouldDoIt === true}
                                            oninput={e => this.save(!a.shouldDoIt, a.id, 'shouldDoIt')} />
                                        <i class="form-icon p-relative" />
                                    </label>
                                </div>
                            ) : (
                                <div class="form-group column col-3 col-sm-4 text-center">
                                    <RadioBtn save={this.save} a={a} value={1} index={index} />
                                    <RadioBtn save={this.save} a={a} value={2} index={index} />
                                    <RadioBtn save={this.save} a={a} value={3} index={index} />
                                    <RadioBtn save={this.save} a={a} value={4} index={index} />
                                    <RadioBtn save={this.save} a={a} value={5} index={index} />
                                </div>
                            )}
                        </div>
                    ))
                ] : null}

                {this.questions.value.showFreeText ? (
                    <div class="form-group">
                        <h5 class="form-label" for="input-textarea">
                            {this.questions.value.freetextQuestion}
                        </h5>
                        <textarea class="form-input" id="input-textarea" style="resize: none;" value={this.questions.value.ownThoughts}
                            onchange={e => this.saveThoughts(e.target.value)} />
                    </div>
                ) : null}
            </div>
        );
    }
};

// parseMessage(_`Are there additional activities that include this goal that you want your organization to work on?`, config)
function parseMessage(string, config) {
    const params = {
        ID: config.courseId,
        COURSE: config.courseName,
        X: config.page,
        USER: config.user,
    };
    const replacer = (...args) => params[args[1]] || args[1];
    return string.replace(/\{([^}])}/g, replacer);
}

const RadioBtnHeader = {
    view({ attrs }) {
        const { value } = attrs;
        return (
            <label class="form-radio form-inline padding-radiobtnheader">
                <span> {value} </span>
            </label>
        );
    }
};

const RadioBtn = {
    view({ attrs }) {
        const { value, a, save, index } = attrs;
        return (
            <label class="form-radio form-inline padding-radiobtn">
                <input type="radio" name={`importantvalue-${index}-${value}`} value={value} checked={a.importantToMeValue === value}
                    oninput={e => save(value, a.id, 'importantToMeValue')} />
                <i class="form-icon"></i>
            </label>
        );
    }
};
