import m from 'mithril';

import './main.scss';

export const NotFound = {
    view({ attrs }) {
        return (
            <div class="text-center">
                <h3>An error occured. {attrs.error}</h3>
                <h4>please contact an administrator</h4>
            </div>
        );
    }
};
