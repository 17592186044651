import _ from 'underscore';
import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { mainStory } from 'storyboard';

export function InitHub(config = {}) {
    mainStory.info('hub', 'initialize');
    if (config.hub) {
        const hub = new HubConnectionBuilder()
            .withUrl(config.hub.url)
            .configureLogging(LogLevel.Information)
            .build();
        hub.on('ReceiveMessage', (label, message) => mainStory.info('system', `[message] ${label} ${message}`));
        hub.on('Logger', message => mainStory.info('system', `logger ${message}`));
        hub.on('Connected', handleConnected(hub, config.bus));
        hub.onclose(retry(hub, config));
        return hub.start()
            .then(() => mainStory.info('hub', 'Connection Started!'))
            .then(() => config.bus(hub))
            .then(() => hub.invoke('Connected', `${config.name} ${config.version}`))
            .then(() => config.bus.emitEvent('hub', 'open'))
            .catch(err => logError('Error while establishing connection :(', err));
    }
    return Promise.reject(new Error('Missing "hub" configuration'));
}

function handleConnected(hub, bus) {
    return (list) => {
        mainStory.info('system', 'connected', { attach: list, attachLevel: 'trace' });
        bus.emitEvent('hub', 'connect');
        return Promise.resolve(list)
            .then(resolver)
            .then(subscriber(hub))
            .then(logInfo)
            .catch(logError);
    };
}

function resolver(list) {
    const filter = item => ['name', 'type'].includes(item.Name);
    const mapper = item => ({ [item.Name]: item.Value });
    const reducer = (current, item) => _.extend({}, current, item);
    return list.map(array => array.filter(filter).map(mapper).reduce(reducer, {}));
}

function subscriber(hub) {
    const filter = item => item.type === 'collection';
    const mapper = (item) => {
        hub.invoke('Load', item.name);
        return item;
    };
    return (list) => {
        mainStory.info('hub', 'subscriber', { attach: list, attachLevel: 'trace' });
        return list.filter(filter).map(mapper);
    };
}

function retry(hub, config) {
    return () => {
        config.bus.emitEvent('hub', 'close');
        const delay = Math.floor(Math.random() * Math.floor(10) * 1000);
        config.bus.emitEvent('hub:delay', delay);
        return setTimeout(
            () => {
                try {
                    hub.start()
                        .then(() => mainStory.info('hub', 'Connection Started!'))
                        .then(() => hub.invoke('Connected', `${config.name} ${config.version}`))
                        .then(() => config.bus.emitEvent('hub', 'reopen'))
                        .catch(err => retry(hub, config));
                } catch (e) {
                    retry(hub, config);
                }
            },
            delay
        );
    };
}

function logInfo(...args) {
    mainStory.info('system', 'log', { attach: args, attachLevel: 'trace' });
}
function logError(...args) {
    mainStory.error('system', 'error', { attach: args, attachLevel: 'trace' });
}
