import m from 'mithril';
import { Agora } from 'agora';
import { Archon } from 'archon';
import { Panel } from 'common/components/panel';

export const App = {
    view() {
        const config = window.GlobalConfig;
        let View = Agora;
        if (config.section === 'archon') {
            View = Archon;
        }
        return (
            <Panel class="panel-noborder">
                <View slot="content" config={config} />
                {/* <pre slot="footer">{JSON.stringify(window.GlobalConfig, null, 2)}</pre> */}
            </Panel>
        );
    }
};
