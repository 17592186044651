import { mainStory } from 'storyboard';
import { Bus } from './utils/bus';

const data = {
    theme: '',
    keys: [],
};

Bus.observe('Config', SetUpTheme);

export function SetUpTheme(config) {
    mainStory.info('theme', 'Config', { attach: config, attachLevel: 'trace' });
    const themes = gatherAvailableThemesFrom(config);
    SetThemes(themes);
    SetTheme(config.theme || themes[0]);
}

function gatherAvailableThemesFrom(config) {
    if (config.availableThemes) {
        return config.availableThemes.split(',').map(theme => theme.replace(/ /g, ''));
    }
    return [].filter.call(document.body.classList, cls => cls.indexOf('theme-') === 0).map(cls => cls.replace('theme-', ''));
}

export function SetThemes(newThemes) {
    data.keys = newThemes;
    Bus.emitLocal('themes', data.keys);
    mainStory.info('theme', 'Set theme list', { attach: data.keys, attachLevel: 'trace' });
}

export function SetTheme(newTheme) {
    if (data.keys.includes(newTheme)) {
        mainStory.info('theme', `Set theme to ${newTheme}`);
        document.body.className = `theme-${newTheme}`;
        data.theme = newTheme;
        Bus.emitLocal('theme', data.theme);
        return true;
    }
    mainStory.info('theme', `Could not find theme ${newTheme}`);
    return false;
}
