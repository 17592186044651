import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { Card } from 'common/components/card';

export const Main = {
    oninit() {
        this._listeners = [];

        this.courses = new Observable([])
            .each(() => m.redraw());
        this._listeners.push(Bus.observe('Courses', data => this.courses(data)));

        this.copied = new Observable(null)
            .each(() => m.redraw());
    },
    oncreate() {
        Bus.secure('GetCourses');
    },
    onremove() {
        this._listeners.forEach(dispose => dispose());
    },
    view({ attrs }) {
        const { user } = attrs;
        return (
            <div class="columns mx-1">
                {user === 'admin'
                    ? (
                        <Card class="column col-3">
                            <div slot="header">
                                <h3>Handle users</h3>
                                <h4><br /></h4>
                            </div>
                            <div slot="content" class="text-center">
                                <button class="btn btn-success mx-2 tooltip" data-tooltip="Handle users" onclick={e => Bus.secure('GetAccounts')}>
                                    <icon class="icon icon-people" />
                                </button>
                            </div>
                        </Card>
                    ) : null}

                <Card class="column col-3">
                    <div slot="header">
                        <h3>New course</h3>
                        <h4><br /></h4>
                    </div>
                    <div slot="content" class="text-center">
                        <button class="btn btn-success mx-2 tooltip" data-tooltip="Create a new course" onclick={e => Bus.secure('NewCourse')}>
                            <icon class="icon icon-plus" />
                        </button>
                        <button class="btn btn-primary mx-2 tooltip" data-tooltip="Create a new course from copied content"
                            disabled={this.copied.value === null} onclick={e => Bus.secure('NewCourseFromCopied', this.copied.value)}>
                            <icon class="icon icon-copy" />
                        </button>
                    </div>
                </Card>

                {this.courses.value.map(course => (
                    <Card key={course.id} class="column col-3">
                        <div slot="header">
                            <h3>{course.name}</h3>
                            <h4>{course.courseId}</h4>
                        </div>
                        <div slot="content" class="text-center">
                            <button class="btn btn-success mx-2 tooltip" data-tooltip="Edit course" onclick={e => Bus.secure('EditCourse', course)}>
                                <icon class="icon icon-edit" />
                            </button>
                            <button class="btn btn-primary mx-2 tooltip" data-tooltip="Copy course" onclick={e => this.copied(course)}>
                                <icon class="icon icon-copy" />
                            </button>
                            <button class="btn btn-error mx-2 tooltip" data-tooltip="Delete course" onclick={e => Bus.secure('DeleteCourse', course)}>
                                <icon class="icon icon-delete" />
                            </button>
                        </div>
                        <p slot="footer" class="text-right text-italic text-muted text-tiny">
                            last edited: {new Date(course.lastEdited).toLocaleString()}
                        </p>
                    </Card>
                ))}

                <div class="column col-12 divider"></div>

                <Card class="column col-12">
                    <div slot="header">
                        <h3>Code to add to the text module in code view:</h3>
                        <h6 class="text-mute">Don't forget to switch back to the text view before saving</h6>
                    </div>
                    <div slot="content">
                        <code class="code-snippet-block">
                            {'<iframe src="https://formit.exceed.transportlead.com/?user={{FULL_NAME}}&email={{EMAIL}}&groupId={{GROUP_ID}}'
                                + '&groupName={{GROUP_NAME}}&courseId={{COURSE_ID}}&courseName={{COURSE_NAME}}&page={{SECTION_ID}}" scrolling="no"'
                                + 'class="h5p-iframe-content h5p-iframe-wrapper" style="width: 100%; height: 450px;" frameborder="0"></iframe>'}
                        </code>
                    </div>
                </Card>
            </div>
        );
    }
};
