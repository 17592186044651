import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { PrintPDF } from './pdf';
import './main.scss';

export const Form = {
    removefromarray(array, valueToRemove) {
        for (let i = array.length; i >= 0; i--) {
            if (array[i] === valueToRemove) {
                array.splice(i, 1);
            }
        }
        return array;
    },
    save() {
        if (!this.courseIds.value.includes(this.course.courseId)
            && !this.course.questionPages.map(page => this.pageIds.value.includes(page.pageId)).includes(true)
            && this.questionsUnique) {
            Bus.secure('SaveCourse', this.course);
        } else {
            this.saved('error');
        }
    },
    oninit({ attrs }) {
        this._listeners = [];

        this.saved = new Observable('success')
            .each(() => m.redraw());
        this._listeners.push(Bus.observe('Message', data => this.saved(data)));
        this._listeners.push(Bus.listen('SaveCourse', () => this.saved('loading')));

        this.saveCourse = (value, type) => {
            if (value && value !== 'null') {
                this.course[type] = value;
                this.save();
            }
        };
        this.savePage = (value, id, type) => {
            this.course.questionPages.find(x => x.id === id)[type] = value;
            this.saved('primary');
        };
        this.saveQuestion = (value, pageId, question) => {
            this.course.questionPages.find(x => x.id === pageId).questions.find(x => x.id === question.id).question = value;
            this.questionsUnique = !this.course.questionPages.find(p => p.id === pageId).questions
                .map(q => (q.id !== question.id ? q.question : '')).includes(question.question);
            this.saved('primary');
        };
        this.questionsUnique = true;

        this.groups = [];
        this.selectedGroup = new Observable(0);
        this._listeners.push(Bus.observe('PrintCourse', data => this.data(data)));
        this.data = new Observable(null)
            .each(() => m.redraw());
        this.printed = () => {
            this.data(null);
        };

        this.courseIds = new Observable([])
            .map(value => this.removefromarray(value, 0))
            .each(value => (value.includes(NaN) ? null : value.push(NaN)));
        this.pageIds = new Observable([])
            .map(value => this.removefromarray(value, 0))
            .each(value => (value.includes(NaN) ? null : value.push(NaN)));
        this._listeners.push(Bus.observe('Send', (type, data) => this[type](data)));
    },
    oncreate() {
        Bus.secure('GetAllIds', this.course);
    },
    onremove() {
        this._listeners.forEach(dispose => dispose());
    },
    view({ attrs }) {
        const { course, groups } = attrs;
        if (this.data.value !== null) {
            return (
                <PrintPDF data={this.data.value} printed={this.printed} />
            );
        }
        if (course !== null) {
            this.course = course;
        }
        if (groups !== null) {
            this.groups = groups;
        }
        return (
            <div class="columns mx-1">
                <div class="column col-1">
                    <button class="btn" onclick={e => Bus.secure('NoCourse')}><icon class="icon icon-back" /></button>
                </div>

                <div class="column col-10 text-center">
                    <select class="form-select" style="width:200px" onchange={e => this.selectedGroup(e.target.value)}>
                        <option value={'null'}>Choose a group</option>
                        {this.groups.map(group => (
                            <option key={group.id} value={group.id}>{group.name}</option>
                        ))}
                    </select>
                    <button class="btn btn-primary mx-2 tooltip" data-tooltip="Download results from course"
                        disabled={!this.selectedGroup.value || this.selectedGroup.value === 'null'}
                        onclick={e => Bus.secure('PrintCourse', parseInt(this.selectedGroup.value, 10), parseInt(this.course.courseId, 10))}>
                        <icon class="icon icon-downward" />
                    </button>
                </div>

                <div class={`column col-1 save-size text-${this.saved.value}`}>
                    {this.saved.value === 'success' ? (<div class="text-center"><icon class="icon icon-check" /></div>) : ''}
                    {this.saved.value === 'loading' ? (<div class="fullsize loading" />) : ''}
                    {this.saved.value === 'primary' ? (<div class="text-center"><icon class="icon icon-edit" /></div>) : ''}
                    {this.saved.value === 'error' ? (<div class="text-center"><icon class="icon icon-cross" /></div>) : ''}
                </div>

                <div class="column col-12 columns">
                    <div class="column col-10 col-mx-auto">
                        <div class="form-group">
                            <label class="form-label text-large" for="input-name">Course Name:</label>
                            <input class="form-input" type="text" id="input-name" placeholder="Name" value={this.course.name}
                                onchange={e => this.saveCourse(e.target.value, 'name')} />
                        </div>

                        <div class="form-group">
                            <label class="form-label text-large" for="select-language">Course Language:</label>
                            <select class="form-select" id="select-language" onchange={e => this.saveCourse(e.target.value, 'language')}>
                                {!this.course.language ? <option value={'null'}>Please choose a language</option> : null}
                                <option value={'sv'} selected={this.course.language === 'sv'}>Swedish</option>
                                <option value={'en'} selected={this.course.language === 'en'}>English</option>
                            </select>
                        </div>

                        <div class={`form-group ${this.courseIds.value.includes(this.course.courseId) ? 'has-error' : ''}`}>
                            <label class="form-label text-large" for="input-courseid">Course Id:</label>
                            <input class="form-input" type="number" id="input-courseid" placeholder="Course Id" value={this.course.courseId}
                                onchange={e => this.saveCourse(parseInt(e.target.value, 10), 'courseId')} />
                            <p class="form-input-hint">
                                {this.courseIds.value.includes(this.course.courseId) ? Number.isNaN(this.course.courseId)
                                    ? 'The Course Id cannot be empty or is not the correct format'
                                    : 'The Course Id is already used somewhere else' : ''}
                            </p>
                        </div>

                        <div class="d-inline-flex my-2">
                            <button class="btn btn-sm mx-2 btn-success tooltip" data-tooltip="New Page"
                                onclick={e => Bus.secure('NewPage', this.course)}>
                                <icon class="icon icon-plus" />
                            </button>
                            <h4 class="ml-2">
                                New Page
                            </h4>
                        </div>

                        {this.course.questionPages.map(page => (
                            <div key={page.index} class="accordion">
                                <input type="checkbox" id={`accordion-${page.index}`} name="accordion-checkbox" hidden />
                                <label class="accordion-header d-inline-block text-large text-bold" for={`accordion-${page.index}`}>
                                    <i class="icon icon-arrow-right"></i>
                                    {page.index}. {page.name} ({page.pageId})
                                    <button class="btn btn-primary btn-sm mx-2 tooltip tooltip-right" data-tooltip={'Move up'}
                                        disabled={page.index === 1}
                                        onclick={e => Bus.secure('MovePageIndex', this.course, page, -1)}>
                                        <icon class="icon icon-arrow-up" />
                                    </button>
                                    <button class="btn btn-primary btn-sm mx-2 tooltip tooltip-right" data-tooltip={'Move down'}
                                        disabled={page.index === this.course.questionPages.length}
                                        onclick={e => Bus.secure('MovePageIndex', this.course, page, 1)}>
                                        <icon class="icon icon-arrow-down" />
                                    </button>
                                    <button class="btn btn-error btn-sm mx-2 tooltip tooltip-right" data-tooltip={`Delete ${page.name}`}
                                        onclick={e => Bus.secure('DeleteQuestionPage', this.course, page)}>
                                        <icon class="icon icon-delete" />
                                    </button>
                                </label>

                                <div class="accordion-body p-1">
                                    <div class="form-group">
                                        <label class="form-label" for={`input-pagename-${page.id}`}>Name:</label>
                                        <input class="form-input" type="text" id={`input-pagename-${page.id}`} placeholder="Name"
                                            value={page.name} oninput={e => this.savePage(e.target.value, page.id, 'name')}
                                            onchange={e => this.save()} />
                                    </div>
                                    <div class={`form-group ${this.pageIds.value.includes(page.pageId) ? 'has-error' : ''}`}>
                                        <label class="form-label" for={`input-pageid-${page.id}`}>Page Id:</label>
                                        <input class="form-input" type="number" id={`input-pageid-${page.id}`}
                                            value={page.pageId} placeholder="PageId" onchange={e => this.save()}
                                            oninput={e => this.savePage(parseInt(e.target.value, 10), page.id, 'pageId')} />
                                        <p class="form-input-hint">
                                            {this.pageIds.value.includes(page.pageId) ? Number.isNaN(page.pageId)
                                                ? 'The Page Id cannot be empty or is not the correct format'
                                                : 'The Page Id is already in use somewhere else' : ''}
                                        </p>
                                    </div>
                                    <div class="columns">
                                        <div class="form-group column col-2 col-ml-auto">
                                            <label class="form-switch">
                                                <input type="checkbox" onchange={e => this.save()} checked={page.showFreeText}
                                                    oninput={e => this.savePage(e.target.checked, page.id, 'showFreeText')} />
                                                <i class="form-icon"></i> Show Freetext
                                            </label>
                                        </div>
                                        <div class="form-group column col-2 col-ml-auto">
                                            <label class="form-switch">
                                                <input type="checkbox" onchange={e => this.save()} checked={page.showQuestion}
                                                    oninput={e => this.savePage(e.target.checked, page.id, 'showQuestion')} />
                                                <i class="form-icon"></i> Show Questions
                                            </label>
                                        </div>
                                        <div class="form-group column col-5 col-ml-auto important-to-me-radio">
                                            <label class="form-inline">
                                                Important Selection:
                                            </label>
                                            <label class="form-radio form-inline">
                                                <input type="radio" name={`radio-${page.id}`}
                                                    value={0} checked={page.importantSelection === 0}
                                                    onchange={e => this.save()} onclick={e => this.savePage(0, page.id, 'importantSelection')} />
                                                <i class="form-icon"></i> Box
                                            </label>
                                            <label class="form-radio form-inline">
                                                <input type="radio" name={`radio-${page.id}`}
                                                    value={1} checked={page.importantSelection === 1}
                                                    onchange={e => this.save()} onclick={e => this.savePage(1, page.id, 'importantSelection')} />
                                                <i class="form-icon"></i> Radio Buttons (1 - 5)
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-group pb-2 mb-2">
                                        <label class="form-label" for={`input-multipleChoice-${page.id}`}>Multiple Choice Question:</label>
                                        <input class="form-input" type="text" id={`input-multipleChoice-${page.id}`}
                                            placeholder="Multiple Choice Question" value={page.multipleChoiceQuestion} onchange={e => this.save()}
                                            oninput={e => this.savePage(e.target.value, page.id, 'multipleChoiceQuestion')} />
                                    </div>

                                    {page.showFreeText ? (
                                        <div class="form-group pb-2 mb-2">
                                            <label class="form-label" for={`input-freetext-${page.id}`}>Freetext Question:</label>
                                            <input class="form-input" type="text" id={`input-freetext-${page.id}`} placeholder="Freetext Question"
                                                value={page.freetextQuestion} onchange={e => this.save()}
                                                oninput={e => this.savePage(e.target.value, page.id, 'freetextQuestion')} />
                                        </div>
                                    ) : null}

                                    {page.showQuestion ? (
                                        <div class="m-2">
                                            <div class="d-inline-flex my-2">
                                                <button class="btn btn-sm btn-success tooltip tooltip-right" data-tooltip="New Question"
                                                    onclick={e => Bus.secure('NewQuestion', this.course, page.id)}>
                                                    <icon class="icon icon-plus" />
                                                </button>
                                                <h5 class="ml-2">
                                                    New Question
                                                </h5>
                                            </div>

                                            {page.questions.map((question, index) => (
                                                <div key={index}>
                                                    <div class={`form-group ${page.questions.map(q => (q.id !== question.id ? q.question : ''))
                                                        .includes(question.question) ? 'has-error' : ''}`}>
                                                        <label class="form-label" for={`input-question-${question.id}`}>
                                                            <button class="btn btn-minus btn-sm mx-2 tooltip tooltip-right"
                                                                data-tooltip={`Delete question ${index + 1}`}
                                                                onclick={e => Bus.secure('DeleteQuestion', this.course, page.id, question.id)}>
                                                                <icon class="icon icon-minus" />
                                                            </button>
                                                            Question {index + 1}:
                                                        </label>
                                                        <input class="form-input" type="text" id={`input-question-${question.id}`}
                                                            placeholder="Question" value={question.question} onchange={e => this.save()}
                                                            oninput={e => this.saveQuestion(e.target.value, page.id, question)} />
                                                        <p class="form-input-hint">
                                                            {page.questions.map(q =>
                                                                (q.id !== question.id ? q.question : '')).includes(question.question)
                                                                ? 'The Questions need to be unique on each page'
                                                                : ''
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
};
